import React from 'react'
import cx from 'classnames'
import DOMPurify from 'dompurify'

import { formatDate, formatResourceString } from 'utils/formatters'
import CaseSummaryIcon from './CaseSummaryIcon'

export default ({ summaryData, pageResource }) => {
  const summaries = React.useMemo(() => {
    if (summaryData) {
      const regex = /https?:\/\/[^\s]+/ // Regex to match URLs

      return summaryData.map((summary, index) => {
        let comment = summary.comment

        if (summary.comment) {
          const parsedComment = summary.comment.replace(regex, url => {
            return `<a href="${url}" target="_blank" rel="noopener noreferrer">Track & Trace</a>`
          })

          const sanitizeComment = DOMPurify.sanitize(parsedComment, { ADD_ATTR: ['target'] })
          comment = sanitizeComment
        }

        const newSummaryContent = formatResourceString(pageResource[summary.eventName], {
          SupplierName: summary.supplierName,
          lines: summary.lineIndex || summary.text,
          url: '',
          POReferenceClient: summary.externalPoReference
        })

        return {
          ...summary,
          summaryDisplaySection: (
            <React.Fragment key={index}>
              <div>{newSummaryContent}</div>
              {!!comment && <div dangerouslySetInnerHTML={{ __html: comment }} />}
            </React.Fragment>
          ),
        }
      })
    }
    return []
  }, [summaryData, pageResource])

  return (
    <div className="timeline">
      {summaries.map((summary, index) => {
        return (
          <div
            key={index}
            className={cx('timeslot', {
              alt: index % 2 !== 0,
            })}
          >
            <div className="task">
              {summary.summaryDisplaySection}

              <div className="timeline-footer">
                <span className="timeline-actor">
                  <i className={cx('material-icons-outlined md-10 text-muted')}>edit</i> {summary.actorName}
                </span>
              </div>
            </div>

            <CaseSummaryIcon summary={summary} />

            <div className="time">
              <span>{formatDate(summary.timestamp, 'dddd, D MMMM YYYY, H:mm')}</span>
            </div>
          </div>
        )
      })}
    </div>
  )
}
