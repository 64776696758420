import BaseStore from 'stores/base'

export default class CaseSummaryStore extends BaseStore {
  constructor(api, options) {
    super(api)
    this.api = api
    this.options = options
  }
}

