import Api from 'services/api'
import PraApi from 'services/praApi'

import AccountClientService from 'services/accountClient'
import AccountService from 'services/account'
import AuthService from 'services/auth'
import CaseService from 'services/case'
import CaseSummaryService from 'services/caseSummary'
import ClientAreaEmployeeService from 'services/clientAreaEmployee'
import ClientAreaService from 'services/clientArea'
import ClientCaseService from 'services/clientCase'
import ClientRequesterService from 'services/clientRequester'
import ClientService from 'services/client'
import ClientSupplierService from 'services/clientSupplier'
import ConnectionCartService from 'services/connectionCart'
import ConnectionService from 'services/connection'
import FeedbackService from 'services/feedback'
import FileService from 'services/file'
import GlobalDataService from 'services/globalData'
import GlobalMessageService from 'services/globalMessage'
import GoodsReceivedService from 'services/goodsReceived'
import HomeService from 'services/home'
import NewsService from 'services/news'
import OidcService from 'services/oidc'
import OrderService from 'services/order'
import PageResourceService from 'services/pageResource'
import PraService from 'services/pra'
import ProposalService from 'services/proposal'
import PurchaseOrderService from 'services/purchaseOrder'
import PurchaseRequestService from 'services/purchaseRequest'
import QuoteService from 'services/quote'
import ReportService from 'services/report'
import Saml2Service from 'services/saml2'
import SourceService from 'services/source'
import SupplierService from 'services/supplier'
import WebformService from 'services/webform'
import WebshopCartService from 'services/webshopCart'
import WebshopCategoryService from 'services/webshopCategory'
import WebshopFavouriteService from 'services/webshopFavourite'
import WebshopPricingService from 'services/webshopPricing'
import WebshopService from 'services/webshop'

const api = new Api({ apiUrl: process.env.REACT_APP_API_URL })
const praApi = new PraApi({ apiUrl: process.env.REACT_APP_PRA_API_URL })

export default {
  accountClientService: new AccountClientService(api),
  accountService: new AccountService(api),
  authService: new AuthService(api),
  clientAreaEmployeeService: new ClientAreaEmployeeService(api),
  clientAreaService: new ClientAreaService(api),
  caseService: new CaseService(api),
  caseSummaryService: new CaseSummaryService(api),
  clientCaseService: new ClientCaseService(api),
  clientRequesterService: new ClientRequesterService(api),
  clientService: new ClientService(api),
  clientSupplierService: new ClientSupplierService(api),
  connectionCartService: new ConnectionCartService(api),
  connectionService: new ConnectionService(api),
  feedbackService: new FeedbackService(api),
  fileService: new FileService(api),
  globalDataService: new GlobalDataService(api),
  globalMessageService: new GlobalMessageService(api, praApi),
  goodsReceivedService: new GoodsReceivedService(api),
  homeService: new HomeService(api),
  newsService: new NewsService(api),
  oidcService: new OidcService(api),
  orderService: new OrderService(api),
  praService: new PraService(praApi),
  pageResourceService: new PageResourceService(api),
  proposalService: new ProposalService(api),
  purchaseOrderService: new PurchaseOrderService(api),
  purchaseRequestService: new PurchaseRequestService(api),
  quoteService: new QuoteService(api),
  reportService: new ReportService(api),
  saml2Service: new Saml2Service(api),
  sourceService: new SourceService(api),
  supplierService: new SupplierService(api),
  webformService: new WebformService(api),
  webshopCartService: new WebshopCartService(api),
  webshopCategoryService: new WebshopCategoryService(api),
  webshopFavouriteService: new WebshopFavouriteService(api),
  webshopPricingService: new WebshopPricingService(api),
  webshopService: new WebshopService(api),
}
