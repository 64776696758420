import React from 'react'

import cx from 'classnames'
import Icon from 'common/Icon'

export default ({
  type = 'button',
  icon,
  iconFilled,
  color = 'primary',
  disabled,
  className,
  iconClassName,
  onClick,
  iconSymbol,
  ...rest
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      tabIndex={-1}
      {...(!disabled ? { onClick } : {})}
      className={cx(`btn-icon btn-icon-${color}`, className)}
      {...rest}
    >
      <Icon icon={icon} filled={iconFilled} symbol={iconSymbol} className={cx(iconClassName)} />
    </button>
  )
}
