import moment from 'moment'

export const defaultDateFormat = 'YYYY-MM-DD'
export const defaultTimeFormat = 'H:mm'
export const defaultLongDateFormat = 'dddd, YYYY-MM-DD, H:mm'
export const praLongDateFormat = 'dddd, DD MMMM YYYY'
export const praLongDateTimeFormat = 'dddd, DD MMMM YYYY, H:mm'

export const jsonToQueryString = (payload = {}) =>
  Object.keys(payload)
    .filter(key => payload[key] !== null && payload[key] !== undefined)
    .map((key, index) => `${!index ? '?' : ''}${key}=${encodeURIComponent(payload[key])}`)
    .join('&')

export const formatFileUrl = (fileId, params) => {
  if (params) {
    if (params.ing) {
      return `${process.env.REACT_APP_API_URL}/file/ing/${encodeURI(params.fileName)}`
    } else if (params?.hash.temp) {
      return `${process.env.REACT_APP_API_URL}/file/temp/${fileId}`
    }
  }
  return `${process.env.REACT_APP_API_URL}/file/${fileId}`
}

export const groupNumber = num => {
  const tokens = num.toString().split('.')
  return `${tokens[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}${tokens[1] ? `.${tokens[1]}` : ''}`
}

export const formatNumber = (num = 0, decimal = 0) => groupNumber(parseFloat(num).toFixed(decimal))

export const formatCurrency = (num = 0, decimal = 2) => `€ ${formatNumber(num, decimal)}`

export const formatNameUrl = value => (value ? value.split(' ').join('-').toLowerCase() : value)

export const getFormattedDateString = (dateString, defaultValue = '') => {
  const isValidDate = dateString && moment(dateString).isValid()

  if (!isValidDate) {
    return defaultValue
  }

  return moment(dateString).format(defaultDateFormat)
}

export const getFormattedDateTimeString = (dateTimeString, defaultValue = '') => {
  const isValidDate = dateTimeString && moment(dateTimeString).isValid()
  if (!isValidDate) {
    return defaultValue
  }

  return moment(dateTimeString).format(`${defaultDateFormat} ${defaultTimeFormat}`)
}

export const getFormattedUtcDateTimeString = (dateTimeString, offset, defaultValue = '') => {
  if (offset) {
    const momentDate = moment.utc(dateTimeString)
    const isValidDate = dateTimeString && momentDate.isValid()
    if (!isValidDate) {
      return defaultValue
    }

    return momentDate.utcOffset(offset).format(`${defaultDateFormat} ${defaultTimeFormat}`)
  }
  return 'Timezone not set'
}

export const formatDate = (dateTime, format = defaultDateFormat) => formatDateTime(dateTime, format)

export const formatDateTime = (dateTime, format = `${defaultDateFormat} ${defaultTimeFormat}`) => {
  if (dateTime) {
    const momentDate = moment(dateTime)
    if (momentDate.isValid()) {
      return momentDate.format(format)
    }
  }
  return ''
}

export const parseDate = (dateTime, format = defaultDateFormat) => parseDateTime(dateTime, format)

export const parseDateTime = (dateTime, format = `${defaultDateFormat} ${defaultTimeFormat}`) => {
  if (dateTime) {
    const momentDate = moment(dateTime, format)
    if (momentDate.isValid()) {
      return momentDate.toDate()
    }
  }
  return ''
}

export const formatString = function (value, ...args) {
  if (value && args) {
    return value.replace(/{(\d+)}/g, (_, index) => {
      const argValue = args[index]
      if (argValue !== null && args[index] !== undefined) {
        return argValue
      }
      return ''
    })
  }
  return value
}

export const formatResourceString = function (value, data) {
  if (value && data) {
    return value.replace(/{{(.*?)}}/g, (match, key) => {
      const argValue = data[key.trim()]
      if (argValue !== null && argValue !== undefined) {
        return argValue
      }
      return ''
    })
  }
  return value
}
