import React from 'react'
import Icon from 'common/Icon'
import Card from 'common/Card'

export default ({ statuses }) => {
  const renderIcon = icon => {
    return <Icon icon={icon} className="fs-5" />
  }

  const renderStatusIcon = icon => {
    return <Icon icon={icon} filled={true} className="fs-4 ms-auto" />
  }

  const iconMap = {
    true: 'done',
    false: 'close',
    null: 'close',
  }

  const statusItems = [
    { label: 'All PR lines Proposed', key: 'allPrLinesProposed' },
    { label: 'All PO Lines Ordered', key: 'allPoLinesOrdered' },
    { label: 'All PO lines Purchase Invoiced', key: 'allPoLinesPurchaseInvoiced' },
    { label: 'All PO lines Sales Invoiced', key: 'allPoLinesSalesInvoiced' },
    { label: 'All Purchase Invoices Paid', key: 'allPurchaseInvoicesPaid' },
    { label: 'All Sales Invoices Paid', key: 'allSalesInvoicesPaid' },
  ]

  const statusIconMap = {
    allPrLinesProposed: 'thumb_up',
    allPoLinesOrdered: 'description',
    allPoLinesPurchaseInvoiced: 'descriptionlocal_shipping',
    allPoLinesSalesInvoiced: 'descriptionperson',
    allPurchaseInvoicesPaid: 'eurolocal_shipping',
    allSalesInvoicesPaid: 'europerson',
  }

  return (
    <Card title="Status" outline className="mb-6" bodyClassName="p-0">
      <div className="status-list list-group rounded-0">
        {statusItems.map(({ label, key }, index) => {
          const status = statuses[key]
          const icon = iconMap[status]
          const statusIcon = statusIconMap[key]

          return (
            <div className="list-group-item d-flex align-items-center border-0 border-bottom" key={index}>
              {status === null ? (
                <React.Fragment>
                  <span className="badge bg-secondary">{renderIcon(icon)}</span>
                  <span className="d-inline-block ms-2 text-muted">{label}</span>
                </React.Fragment>
              ) : status === true ? (
                <React.Fragment>
                  <span className="badge bg-success">{renderIcon(icon)}</span>
                  <span className="d-inline-block ms-2">{label}</span>
                  {renderStatusIcon(statusIcon)}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <span className="badge bg-danger">{renderIcon(icon)}</span>
                  <span className="d-inline-block ms-2">{label}</span>
                  {renderStatusIcon(statusIcon)}
                </React.Fragment>
              )}
            </div>
          )
        })}
      </div>
    </Card>
  )
}
