import BaseService from 'services/base'

export default class CaseSummary extends BaseService {
  constructor(api) {
    const url = 'case'
    super(api, url)
    this.api = api
    this.url = url
  }

  fetchTable = caseId => {
    return this.api.get(`case/${caseId}/timeline`)
  }
}
