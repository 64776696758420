import React from 'react'
import cx from 'classnames'
import Icon from 'common/Icon'

const renderIcon = (icon, priority) => {
  return (
    <Icon
      icon={icon}
      className={cx('icon rounded-circle', {
        'bg-warning': priority.toUpperCase() === 'MEDIUM',
        'bg-danger': priority.toUpperCase() === 'HIGH',
        'bg-success': priority.toUpperCase() === 'LOW',
        'bg-primary': !['MEDIUM', 'HIGH', 'LOW'].includes(priority.toUpperCase()),
      })}
    />
  )
}

const iconMap = {
  ORDER: 'description',
  CASE: 'list_alt',
  SOURCE: 'local_shipping',
  SLA: 'access_time',
  PROPOSE: 'format_list_bulleted',
  PI: 'local_shipping',
  SI: 'person',
  COMMUNICATION: 'mail_outline',
  CONTROLLER: 'gavel',
}

export default ({ summary }) => {
  const { category, priority } = summary
  const icon = iconMap[category.toUpperCase()]

  return <div className="timeline-icon">{icon ? renderIcon(icon, priority) : <Icon icon="info" />}</div>
}
