import { getValueByPath } from 'utils'

export const getError = (errors = {}, name) => {
  const error = errors && getValueByPath(errors, name)
  if (error) {
    if (error.type === 'required' && !error.message) {
      return 'This field is required'
    } else {
      return error.message
    }
  }
  return null
}

export const validateMinimum = (value, min, message) => {
  const numberValue = typeof value === 'string' || value instanceof String ? parseFloat(value) : value
  return numberValue < min ? message : true
}

export const validateMaxLength = (value = '', max = 0, message) => {
  return value && max > 0 && value.length > max ? message : true
}

export const validateSpecialCharacters = (value, message) => {
  return value && !/^[A-Za-z0-9_\-\s./()]*$/.test(value) ? message : true
}

export const validateEmail = (value, message) => {
  return !!value && // eslint-disable-next-line
    !/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(
      value
    )
    ? message
    : true
}

export const validateUrl = (value, message) => {
  return value &&
    !/^(https?|ftp):\/\/(((([a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i.test(
      value
    )
    ? message
    : true
}

export const validatePassword = (value, message) => {
  // eslint-disable-next-line no-useless-escape
  return value && !/^(?=.*\d)[A-Za-z\d\s!"#$%&'()*+,\-.\/:;<=>?@\[\\\]^_`{|}~]{8,}$/.test(value) ? message : true
}

export const validateNumber = (value, message) => {
  if (isNaN(value)) {
    return message
  }
  return true
}

export const validateQuantiy = (value, message) => {
  const result = validateNumber(value, message)
  if (result) {
    if (!/^-?[0-9]\d*$/.test(value)) return message
  }
  return result
}

export const validateDecimal = (value, message) => {
  const result = validateNumber(value, message)
  if (result) {
    if (!/^-?[0-9]\d*(\.\d+)?$/.test(value)) return message
  }
  return result
}
