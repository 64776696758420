import React from 'react'

import { configure } from 'mobx'

import services from 'services'

import AccountClientStore from 'stores/accountClient'
import AccountStore from 'stores/account'
import AuthStore from 'stores/auth'
import CaseStore from 'stores/case'
import CaseSummaryStore from 'stores/caseSummary'
import ClientAreaEmployeeStore from 'stores/clientAreaEmployee'
import ClientAreaStore from 'stores/clientArea'
import ClientCaseStore from 'stores/clientCase'
import ClientRequesterStore from 'stores/clientRequester'
import ClientStore from 'stores/client'
import ClientSupplierStore from 'stores/clientSupplier'
import ConnectionCartStore from 'stores/connectionCart'
import ConnectionStore from 'stores/connection'
import FeedbackStore from 'stores/feedback'
import FileStore from 'stores/file'
import GlobalDataStore from 'stores/globalData'
import GlobalMessageStore from 'stores/globalMessage'
import GoodsReceivedStore from 'stores/goodsReceived'
import HomeStore from 'stores/home'
import NewsStore from 'stores/news'
import OidcStore from 'stores/oidc'
import OrderStore from 'stores/order'
import PageResourceStore from 'stores/pageResource'
import PraStore from 'stores/pra'
import ProposalStore from 'stores/proposal'
import PurchaseOrderStore from 'stores/purchaseOrder'
import PurchaseRequestStore from 'stores/purchaseRequest'
import QuoteStore from 'stores/quote'
import ReportStore from 'stores/report'
import Saml2Store from 'stores/saml2'
import SourceStore from 'stores/source'
import SupplierStore from 'stores/supplier'
import WebformStore from 'stores/webform'
import WebshopCartStore from 'stores/webshopCart'
import WebshopCategoryStore from 'stores/webshopCategory'
import WebshopFavouriteStore from 'stores/webshopFavourite'
import WebshopPricingStore from 'stores/webshopPricing'
import WebshopStore from 'stores/webshop'

configure({
  enforceActions: 'never',
})

const storesContext = React.createContext({
  accountClientStore: new AccountClientStore(services.accountClientService),
  accountStore: new AccountStore(services.accountService),
  authStore: new AuthStore(services.authService),
  clientAreaEmployeeStore: new ClientAreaEmployeeStore(services.clientAreaEmployeeService),
  clientAreaStore: new ClientAreaStore(services.clientAreaService),
  caseStore: new CaseStore(services.caseService),
  caseSummaryStore: new CaseSummaryStore(services.caseSummaryService),
  clientCaseStore: new ClientCaseStore(services.clientCaseService),
  clientRequesterStore: new ClientRequesterStore(services.clientRequesterService),
  clientStore: new ClientStore(services.clientService),
  clientSupplierStore: new ClientSupplierStore(services.clientSupplierService),
  connectionCartStore: new ConnectionCartStore(services.connectionCartService),
  connectionStore: new ConnectionStore(services.connectionService),
  feedbackStore: new FeedbackStore(services.feedbackService),
  fileStore: new FileStore(services.fileService),
  globalDataStore: new GlobalDataStore(services.globalDataService),
  globalMessageStore: new GlobalMessageStore(services.globalMessageService),
  goodsReceivedStore: new GoodsReceivedStore(services.goodsReceivedService),
  homeStore: new HomeStore(services.homeService),
  newsStore: new NewsStore(services.newsService),
  oidcStore: new OidcStore(services.oidcService),
  orderStore: new OrderStore(services.orderService),
  praStore: new PraStore(services.praService),
  pageResourceStore: new PageResourceStore(services.pageResourceService),
  proposalStore: new ProposalStore(services.proposalService),
  purchaseOrderStore: new PurchaseOrderStore(services.purchaseOrderService),
  purchaseRequestStore: new PurchaseRequestStore(services.purchaseRequestService),
  quoteStore: new QuoteStore(services.quoteService),
  reportStore: new ReportStore(services.reportService),
  saml2Store: new Saml2Store(services.saml2Service),
  sourceStore: new SourceStore(services.sourceService),
  supplierStore: new SupplierStore(services.supplierService),
  webformStore: new WebformStore(services.webformService),
  webshopCartStore: new WebshopCartStore(services.webshopCartService),
  webshopCategoryStore: new WebshopCategoryStore(services.webshopCategoryService),
  webshopStore: new WebshopStore(services.webshopService),
  webshopPricingStore: new WebshopPricingStore(services.webshopPricingService),
  webshopFavouriteStore: new WebshopFavouriteStore(services.webshopFavouriteService),
})

export const useStores = () => React.useContext(storesContext)
