export const OAZO = 'OAZO'

export const IS_AUTHORIZED = `${OAZO}_IS_AUTHORIZED`
export const REDIRECT_URL = `${OAZO}_REDIRECT_URL`
export const PRA_TOKEN = `${OAZO}_PRA_TOKEN`
export const REMEMBER_ME = `${OAZO}_REMEMBER_USERNAME`
export const LOGOUT_REQUEST = `${OAZO}_LOGOUT_REQUEST`
export const OFFLINE_MESSAGE = `${OAZO}_OFFLINE_MESSAGE`

export const SPOT_BUY_CENTER = 'Spot Buy Center'

export const APPROVER_STATUS = {
  UNSTARTED: 'Unstarted',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  RELAUNCHED: 'Relaunched',
  REASSIGNED: 'Reassigned',
  REASSIGN: 'Reassign',
  QUESTIONED: 'Questioned',
  ANSWERED: 'Answered',
  ABORTED: 'Aborted',
  OUTOFOFFICE: 'OutOfOffice',
  INVALID: 'Invalid',
}

export const ATTACHMENT_TYPE = {
  Attachment: 'Attachment',
  RandomUpload: 'RandomUpload',
}

export const CASE_STATUS = {
  ACTIVE: 'ACTIVE',
}

export const BE_COUNTRY = {
  code: 'BE',
  name: 'Belgium',
}

export const CASE_TYPE = {
  Webform: 'Webform',
  Webshop: 'Webshop',
  BeforeTheFacts: 'BeforeTheFacts',
  AfterTheFacts: 'AfterTheFacts',
  ContractPo: 'ContractPo',
  SelfService: 'SelfService',
  Connection: 'Connection',
}

export const CLIENTS = {
  TLG: 'TLG',
}

export const DOMAIN_EVENTS = {
  CaseAbortedByControllerEvent: 'CaseAbortedByControllerEvent',
  CaseAbortedEvent: 'CaseAbortedEvent',
  CaseAutoAbortEvent: 'CaseAutoAbortEvent',
  CaseCreatedFromWebshoCatalogEvent: 'CaseCreatedFromWebshoCatalogEvent',
  CaseCreatedFromWebshopPoEvent: 'CaseCreatedFromWebshopPoEvent',
  CaseReleasedEvent: 'CaseReleasedEvent',
  CaseUntakenEvent: 'CaseUntakenEvent',
  ChangeDeliveryDateEvent: 'ChangeDeliveryDateEvent',
  CreateCaseFromATFPoEvent: 'CreateCaseFromATFPoEvent',
  CreateCaseFromBTFPoEvent: 'CreateCaseFromBTFPoEvent',
  CreateCaseFromPoEvent: 'CreateCaseFromPoEvent',
  ManuallyCreateCaseEvent: 'ManuallyCreateCaseEvent',
  OrderCancelEvent: 'OrderCancelEvent',
  OrderRequestForConfirmationSentEvent: 'OrderRequestForConfirmationSentEvent',
  OrderSentEvent: 'OrderSentEvent',
  ProposalBundleRejectedEvent: 'ProposalBundleRejectedEvent',
  ProposalBundleRevokedEvent: 'ProposalBundleRevokedEvent',
  ProposalBundleSentEvent: 'ProposalBundleSentEvent',
  ProposalSelectedEvent: 'ProposalSelectedEvent',
  ProposalUnselectedEvent: 'ProposalUnselectedEvent',
  PurchaseOrderAddedToCaseEvent: 'PurchaseOrderAddedToCaseEvent',
  PurchaseRequestSubmittedEvent: 'PurchaseRequestSubmittedEvent',
  PurchaseRequestTakenEvent: 'PurchaseRequestTakenEvent',
  RfcSentEvent: 'RfcSentEvent',
  RfpiSentEvent: 'RfpiSentEvent',
  RfqSentEvent: 'RfqSentEvent',
  SalesInvoiceSentEvent: 'SalesInvoiceSentEvent',
  SourceRequestForConfirmationSentEvent: 'SourceRequestForConfirmationSentEvent',
}

export const LOGIN_PROVIDER = {
  BELFIUS: 'belfius',
  GOED: 'goed',
  INTERPARKING: 'interparking',
  NUNHEMS: 'nun',
}

export const ADDRESS_TYPE = {
  CLIENT: 'client',
  OTHER: 'other',
}

export const DELIVER_TO = {
  EMPLOYEE: 'employee',
  OTHER: 'other',
}

export const ENV_PRODUCTION = 'production'

export const PUNCHOUT_TYPE = {
  Ariba: 'Ariba',
  Coupa: 'Coupa',
  Hubwoo: 'Hubwoo',
  NonErp: 'NonErp',
  Oracle: 'Oracle',
  SAP: 'SAP',
  SAPXml: 'SAPXml',
}

export const PRA_ACTION = {
  SAVE: 1,
  APPROVE_AND_LAUNCH: 2,
}

export const PRA_APPROVER_STATUS = {
  UNSTARTED: 0,
  APPROVED: 1,
  REJECTED: 2,
  RELAUNCHED: 3,
  REASSIGNED: 4,
  REASSIGN: 5, // to be remove in the future //to handle historical data with 'Reassign value'
  QUESTIONED: 6,
  ANSWERED: 7,
  ABORTED: 8,
  OUT_OF_OFFICE: 9,
  INVALID: 20,
}

export const PRA_GET_APPROVERS_TYPE = {
  DEFAULT: 0,
  FOR_CREATOR_NPLUSX: 1,
  FOR_REASSIGN_APPROVERS: 2,
  FOR_PRA_BY_KEY: 3,
  EXPENSE: 4,
  OUT_OF_OFFICE: 5,
}

export const PRA_PREPARE_TYPE = {
  PENDING_FOR_CREATE: 0,
  CREATE: 1,
  EDIT_PRA: 2,
  COPY_PRA: 3,
}

export const PRA_STATUS = {
  NONE: { id: 0, value: '' },
  UNSTARTED: { id: 1, value: 'Unstarted' },
  PENDING: { id: 2, value: 'Pending' },
  APPROVED: { id: 3, value: 'Approved' },
  REJECTED: { id: 4, value: 'Rejected' },
  ABORTED: { id: 5, value: 'Aborted' },
}

export const ROUTES = {
  CASES: '/cases',
  CONNECTIONS: '/connections',
  CONNECTIONS_CART: '/connections/cart',
  DEFAULT: '/',
  FEEDBACK: '/feedback',
  FORBIDDEN: '/forbidden',
  HOME: '/home',
  LANDING: '/login',
  LOGIN: '/login',
  LOGIN_BELFIUS: '/belfius/login',
  LOGIN_GOED: '/goed/login',
  LOGIN_INTERPARKING: '/interparking/login',
  LOGIN_NUNHEMS: '/nun/login',
  NEWS: '/news',
  OFFLINE: '/offline',
  PRA: '/prapproval',
  RESET_PASSWORD: '/reset-password',
  SUPPLIERS: '/suppliers',
  WEBFORM: '/webform',
  WEBSHOP: '/oazowebshop',
  WEBSHOP_CART: '/oazowebshop/cart',
  WEBSHOP_CATALOG: '/oazowebshop/catalog',
  WEBSHOP_FAVOURITES: '/oazowebshop/favourites',
  WEBSHOP_PRODUCT: '/oazowebshop/product',
  WEBSHOP_PUNCHOUT: '/oazowebshop/punchout',
  WEBSHOP_SEARCH: '/oazowebshop/search',
}

export const SERVICE_CONTRACT = {
  SPOT_BUY_CENTER_CATALOG: 'SpotBuyCenterCatalog',
  CLIENT_CATALOG: 'ClientCatalog',
}

export const WEBSHOP_DEFAULTS = {
  MAX_ITEM_COUNT_SORT_BY_PRICE: 500,
  PAGE_SIZE: 60,
  MIN_PRICE: 0,
  MAX_PRICE: 99999,
}

export const WEBSHOP_ATTRIBUTE_TYPE = {
  SELECT: 'select',
  TEXT: 'text',
  CHECKBOX: 'checkbox',
  EMAIL: 'email',
  PHONE: 'phone',
  NUMBER: 'number',
}

export const PRA_TYPE = {
  WEBFORM: 2,
  WEBSHOP: 1,
  DIY: 8,
  CONNECTION: 9,
}
