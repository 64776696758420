import React from 'react'

import cx from 'classnames'

export default ({ icon, tag: Tag = 'span', id, filled, symbol, title, className, style }) => {
  return (
    <Tag
      id={id}
      title={title}
      className={cx(
        'icon',
        {
          'material-icons': !symbol && filled,
          'material-icons-outlined': !symbol && !filled,
          'material-symbols-outlined filled': symbol && filled,
          'material-symbols-outlined': symbol && !filled,
        },
        className
      )}
      style={style}
    >
      {icon}
    </Tag>
  )
}
