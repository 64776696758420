import React from 'react'
import { observer } from 'mobx-react'
import { useStores } from 'stores'
import CaseSummaryPannel from './CaseSummaryPannel'
import CaseStatus from './CaseStatus'

const CaseSummary = observer(({ activated, caseId, status }) => {
  const { caseSummaryStore, pageResourceStore } = useStores()

  const {
    PageResource: { case: resource = {} },
  } = pageResourceStore

  const {
    Table: { data = {} },
  } = caseSummaryStore
  const caseSummaryData = data.entries

  const statuses = status

  React.useEffect(() => {
    if (activated) {
      caseSummaryStore.fetchTable(caseId)
    }
  }, [caseSummaryStore, caseId, activated])

  return (
    <div id="summary" className="summary-container d-flex flex-column-reverse flex-xl-row">
      <div id="caseSummary" className="timeline-container col">
        <CaseSummaryPannel summaryData={caseSummaryData} pageResource={resource} />
      </div>
      <div id="caseStatus" className="status-container col col-xl-3">
        <CaseStatus statuses={statuses} />
      </div>
    </div>
  )
})

export default CaseSummary
