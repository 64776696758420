import React from 'react'

import { formatResourceString } from 'utils/formatters'
import { DOMAIN_EVENTS } from 'definitions'
import Icon from 'common/Icon'

const CaseEventBadge = ({ icon, color, title }) => {
  return (
    <div className={`d-inline-flex align-items-center me-2 cursor-default`} title={title}>
      <Icon icon={icon} symbol filled className={`fs-28 text-${color}`} />
    </div>
  )
}

export default ({ eventName, resource }) => {
  const title = resource[eventName] ? formatResourceString(resource[eventName], {}) : 'In progress'

  switch (eventName) {
    case DOMAIN_EVENTS.CaseReleasedEvent:
    case DOMAIN_EVENTS.CaseUntakenEvent:
    case DOMAIN_EVENTS.PurchaseRequestSubmittedEvent:
      return <CaseEventBadge icon="pending" color="yellow" title={title} />
    case DOMAIN_EVENTS.ChangeDeliveryDateEvent:
    case DOMAIN_EVENTS.OrderSentEvent:
    case DOMAIN_EVENTS.SalesInvoiceSentEvent:
      return <CaseEventBadge icon="check_circle" color="success" title={title} />
    case DOMAIN_EVENTS.PurchaseRequestTakenEvent:
      return <CaseEventBadge icon="clock_loader_10" color="yellow" title={title} />
    case DOMAIN_EVENTS.OrderRequestForConfirmationSentEvent:
    case DOMAIN_EVENTS.ProposalBundleRevokedEvent:
    case DOMAIN_EVENTS.RfcSentEvent:
    case DOMAIN_EVENTS.RfpiSentEvent:
    case DOMAIN_EVENTS.RfqSentEvent:
    case DOMAIN_EVENTS.SourceRequestForConfirmationSentEvent:
      return <CaseEventBadge icon="clock_loader_20" color="yellow" title={title} />
    case DOMAIN_EVENTS.ProposalBundleSentEvent:
    case DOMAIN_EVENTS.ProposalUnselectedEvent:
      return <CaseEventBadge icon="clock_loader_40" color="yellow" title={title} />
    case DOMAIN_EVENTS.ProposalSelectedEvent:
      return <CaseEventBadge icon="clock_loader_60" color="yellow" title={title} />
    case DOMAIN_EVENTS.CaseCreatedFromWebshoCatalogEvent:
    case DOMAIN_EVENTS.CaseCreatedFromWebshopPoEvent:
    case DOMAIN_EVENTS.CreateCaseFromATFPoEvent:
    case DOMAIN_EVENTS.CreateCaseFromBTFPoEvent:
    case DOMAIN_EVENTS.CreateCaseFromPoEvent:
    case DOMAIN_EVENTS.ManuallyCreateCaseEvent:
    case DOMAIN_EVENTS.PurchaseOrderAddedToCaseEvent:
      return <CaseEventBadge icon="clock_loader_80" color="yellow" title={title} />
    case DOMAIN_EVENTS.CaseAbortedByControllerEvent:
    case DOMAIN_EVENTS.CaseAbortedEvent:
    case DOMAIN_EVENTS.CaseAutoAbortEvent:
    case DOMAIN_EVENTS.OrderCancelEvent:
    case DOMAIN_EVENTS.ProposalBundleRejectedEvent:
      return <CaseEventBadge icon="cancel" color="danger" title={title} />
    default:
      return <CaseEventBadge icon="clock_loader_10" color="yellow" />
  }
}
